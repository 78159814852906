import React, {Component} from 'react'

export default class Project extends Component{
    render() {

        return(
            <div className="container projects-container">
                <h3>Project {}</h3>
                {console.log(this.props)}
            </div>
        );
    };

    renderProject = (key, project) => {
        return (
            <div className="row clearfix" key={key}>
                <div className="col-xs-12 col-sm-4 col-md-3 col-print-12 left-column">
                    <h4>{project.title}</h4><p className="no-print">
                    <a href={project.github.url} target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-github" title={project.github.displayText}></i>
                    </a>
                </p>
                </div>
                <div className="col-xs-12 col-sm-8 col-md-9 col-print-12"><p className="quote">{project.quote}</p>
                    <p>{project.description}</p>

                </div>
            </div>
        );
    };
}