import React, {Component} from 'react'

export default class Projects extends Component{
    render() {
        const styles = {
            backgroundImage: `url(/images/stock-photo-product.jpg)`
        }
        return(
            <div>
                <div className="container projects-container">
                    <h3>Projects page</h3>
                    <div className="products-container">
                        <span style={styles} className={"products-img"}/>
                        <span style={styles} className={"products-img"}/>
                        <span style={styles} className={"products-img"}/>
                    </div>
                </div>
            </div>
        );
    };
}