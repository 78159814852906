import React, {Component} from 'react';
import {changeRoute} from "../../index";

export default class Header extends Component{
    render() {
        return(
            <div className="container header-container">
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-7 header-left">
                        <h1 className="cursor-pointer" onClick={() => {changeRoute('/')}}>Marco Willems</h1>
                        <h2>Student</h2>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-5 header-right">
                        <ul className="icons no-print">
                            <li>
                                <a target="_blank" rel="noopener noreferrer" href="https://github.com/"
                                   className="button button--sacnite button--round-l">
                                    <i className="fa fa-github" title="Github link"></i>
                                </a>
                            </li>
                        </ul>
                        <p>
                            Email: <a href="mailto:work@marcowillems.nl" target="_blank" rel="noopener noreferrer">work@marcowillems.nl</a>
                        </p>
                        <p>
                            Web: <a href="https://www.marcowillems.nl"
                                target="_blank" rel="noopener noreferrer">https://marcowillems.nl
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}